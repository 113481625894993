"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        locale: 'en-US',
        lang: 'en',
    },
    {
        locale: 'zh-CN',
        lang: 'zh',
    },
    {
        locale: 'zh-CN',
        lang: 'cn',
    },
    {
        locale: 'zh-TW',
        lang: 'zt',
    },
    {
        locale: 'zh-HK',
        lang: 'zth',
    },
    {
        locale: 'es-ES',
        lang: 'es',
    },
    {
        locale: 'pt-PT',
        lang: 'pt',
    },
    {
        locale: 'fr-FR',
        lang: 'fr',
    },
    {
        locale: 'de-DE',
        lang: 'de',
    },
    {
        locale: 'it-IT',
        lang: 'it',
    },
    {
        locale: 'ru-RU',
        lang: 'ru',
    },
    {
        locale: 'ja-JP',
        lang: 'ja',
    },
    {
        locale: 'ko-KR',
        lang: 'ko',
    },
    {
        locale: 'ar-SA',
        lang: 'ar',
    },
    {
        locale: 'tr-TR',
        lang: 'tr',
    },
    {
        locale: 'th-TH',
        lang: 'th',
    },
    {
        locale: 'vi-VN',
        lang: 'vi',
    },
    {
        locale: 'my-MM',
        lang: 'buMM',
    },
    {
        locale: 'nl-NL',
        lang: 'nl',
    },
    {
        locale: 'iw-IL',
        lang: 'he',
    },
    {
        locale: 'in-ID',
        lang: 'id',
    },
    {
        locale: 'pl-PL',
        lang: 'pl',
    },
    {
        locale: 'hi-IN',
        lang: 'hi',
    },
    {
        locale: 'uk-UA',
        lang: 'uk',
    },
    {
        locale: 'ms-MY',
        lang: 'ms',
    },
    {
        locale: 'bn-BD',
        lang: 'bdBN',
    },
    {
        locale: 'ne-NP',
        lang: 'neNP',
    },
    {
        locale: 'ur-PK',
        lang: 'urPK',
    },
    {
        locale: 'si-LK',
        lang: 'siLK',
    },
    {
        locale: 'en-SG',
        lang: 'sg',
    },
    {
        locale: 'tl-PH',
        lang: 'ph',
    },
    {
        locale: 'en-TH',
        lang: 'enTH',
    },
    {
        locale: 'en-PH',
        lang: 'enPH',
    },
    {
        locale: 'en-LK',
        lang: 'enLK',
    },
    {
        locale: 'en-PK',
        lang: 'enPK',
    },
    {
        locale: 'en-BD',
        lang: 'enBD',
    },
    {
        locale: 'en-MM',
        lang: 'enMM',
    },
    {
        locale: 'en-NP',
        lang: 'enNP',
    },
    {
        locale: 'en-MY',
        lang: 'enMY',
    },
    {
        locale: 'en-VN',
        lang: 'enVN',
    },
];
