"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pandaEvent = exports.setConfig = void 0;
var broadcast_1 = require("./broadcast");
var eventbus_1 = require("./eventbus");
/** 设置Panda全局配置 */
function setConfig(params) {
    if (params && params.cookieKey) {
        window.__PANDA.cookieKey = params.cookieKey;
    }
    if (params && typeof params.logLevel !== 'undefined') {
        window.__PANDA.logLevel = params.logLevel;
    }
    if (params && params.langList) {
        window.__PANDA.langList = params.langList;
    }
}
exports.setConfig = setConfig;
// 兼容处理
exports.pandaEvent = typeof BroadcastChannel === 'function' ? (new broadcast_1.Broadcast()) : (new eventbus_1.EventBus());
