"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeCookie = exports.getCookie = exports.setCookie = void 0;
var SameSite;
(function (SameSite) {
    SameSite["strict"] = "strict";
    SameSite["lax"] = "lax";
})(SameSite || (SameSite = {}));
function setCookie(key, value, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.path, path = _c === void 0 ? '/' : _c, domain = _b.domain, expires = _b.expires, _d = _b.maxAge, maxAge = _d === void 0 ? 60 * 60 * 14 * 30 : _d, secure = _b.secure, sameSite = _b.sameSite;
    if (!key)
        return;
    value = encodeURIComponent(String(value)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);
    key = encodeURIComponent(String(key));
    key = key.replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent);
    key = key.replace(/[()]/g, escape);
    var attrs = [];
    if (path)
        attrs.push("path=".concat(path)); // path 非常重要, 默认是  location.pathname , 在删除或者修改 cookie 时候, path 没有对齐是无法修改 cookie 的.
    if (domain)
        attrs.push("domain=".concat(domain));
    if (expires)
        attrs.push("expires=".concat(expires));
    //! important:"max-age" is not supported by IE6/7/8
    if (maxAge)
        attrs.push("max-age=".concat(maxAge));
    if (secure)
        attrs.push('secure');
    if (sameSite)
        attrs.push("samesite=".concat(sameSite));
    document.cookie = "".concat(key, "=").concat(value, ";").concat(attrs.join(';'));
}
exports.setCookie = setCookie;
function getCookie(key) {
    var COOKIE_SPLITTER = '; ';
    var KEY_VALUE_SPLITTER = '=';
    var result = '';
    var cookies = document.cookie ? document.cookie.split(COOKIE_SPLITTER) : [];
    var REDCode = /(%[0-9A-Z]{2})+/g;
    for (var i = 0; i < cookies.length; i++) {
        var parts = cookies[i].split(KEY_VALUE_SPLITTER);
        var cookie = parts.slice(1).join(KEY_VALUE_SPLITTER);
        try {
            var name = parts[0].replace(REDCode, decodeURIComponent);
            cookie = cookie.replace(REDCode, decodeURIComponent);
            if (key === name) {
                result = cookie;
                break;
            }
        }
        catch (e) { } // eslint-disable-line
    }
    return result;
}
exports.getCookie = getCookie;
function removeCookie(key, attrs) {
    if (attrs === void 0) { attrs = {}; }
    setCookie(key, '', __assign(__assign({}, attrs), {
        expires: 'Thu, 01 Jan 1970 00:00:00 GMT',
        maxAge: -1,
    }));
}
exports.removeCookie = removeCookie;
