"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../utils");
var const_1 = require("../const");
// 确保新老的基础参数都能被覆盖
window.__PANDA = __assign({ cookieKey: const_1.DEFAULT_COOKIE_KEY, lang: (0, utils_1.getNavigatorLang)(), logLevel: 2, instanceNames: [] }, window.__PANDA);
window._PANDA_I18N = window._PANDA_I18N || {};
if (typeof location !== 'undefined' && location.search) {
    var urlParams = new URLSearchParams(location.search);
    var searchLang = urlParams.get(const_1.DEFAULT_SEARCH_KEY);
    if (searchLang) {
        var formatLang = (0, utils_1.formatLocale)(searchLang);
        if ((0, utils_1.inLang)(formatLang)) {
            window.__PANDA.searchLang = formatLang;
        }
    }
    if (urlParams.get(const_1.PANDA_DEBUG_SEARCH_KEY)) {
        window.__PANDA.debug = true;
    }
}
if (typeof localStorage !== 'undefined') {
    var lang = localStorage.getItem(const_1.DEFAULT_LOCAL_KEY);
    if (lang) {
        window.__PANDA.localLang = lang;
    }
}
