"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultLangList = exports.langList = void 0;
var _langList = [
    {
        value: 'zh-CN',
        label: '中文 (简体)',
    },
    {
        value: 'en-US',
        label: 'English',
    },
    {
        value: 'ja-JP',
        label: '日本語',
    },
    {
        value: 'ru-RU',
        label: 'Русский',
    },
    {
        value: 'pt-PT',
        label: 'Português (Portugal)',
    },
    {
        value: 'fr-FR',
        label: 'Français',
    },
    {
        value: 'es-ES',
        label: 'Español',
    },
    {
        label: 'Arabic',
        value: 'ar-SA',
    },
    {
        label: 'assamese',
        value: 'as',
    },
    {
        label: 'Bangadesh',
        value: 'bn-BD',
    },
    {
        label: 'bangadeshIndia',
        value: 'bn-IN',
    },
    {
        label: 'bhojpuri',
        value: 'bho',
    },
    {
        label: 'Burmese',
        value: 'my-MM',
    },
    {
        label: '中文 (繁体)',
        value: 'zh-HK',
    },
    {
        label: '中文 (Singapore)',
        value: 'zh-SG',
    },
    {
        label: '中文 (繁体)',
        value: 'zh-TW',
    },
    {
        label: 'czech',
        value: 'cs-CZ',
    },
    {
        label: 'danish',
        value: 'da-DK',
    },
    {
        label: 'Dutch(Belgium)',
        value: 'nl-BE',
    },
    {
        label: 'Dutch',
        value: 'nl-NL',
    },
    {
        label: 'Bangladesh English',
        value: 'en-BD',
    },
    {
        label: 'Canadian English',
        value: 'en-CA',
    },
    {
        label: 'Czech English',
        value: 'en-CZ',
    },
    {
        label: 'English (France)',
        value: 'en-FR',
    },
    {
        label: 'Hungarian English',
        value: 'en-HU',
    },
    {
        label: 'Indonesian English',
        value: 'en-ID',
    },
    {
        label: 'Malaysia English',
        value: 'en-MY',
    },
    {
        label: 'Myanmar English',
        value: 'en-MM',
    },
    {
        label: 'Nepal English',
        value: 'en-NP',
    },
    {
        label: 'Pakistan English',
        value: 'en-PK',
    },
    {
        label: 'Philippines English',
        value: 'en-PH',
    },
    {
        label: 'Poland English',
        value: 'en-PL',
    },
    {
        label: 'singapore english',
        value: 'en-SG',
    },
    {
        label: 'English (Spain)',
        value: 'en-ES',
    },
    {
        label: 'Srilanka English',
        value: 'en-LK',
    },
    {
        label: 'Thai English',
        value: 'en-TH',
    },
    {
        label: 'British English',
        value: 'en-GB',
    },
    {
        label: 'Vietnamese English',
        value: 'en-VN',
    },
    {
        label: 'German',
        value: 'de-DE',
    },
    {
        label: 'gujarati',
        value: 'gu',
    },
    {
        label: 'Hebrew',
        value: 'iw-IL',
    },
    {
        label: 'Hindi',
        value: 'hi-IN',
    },
    {
        label: 'hungarian',
        value: 'hu',
    },
    {
        label: 'Indonesian',
        value: 'in-ID',
    },
    {
        label: 'Italian',
        value: 'it-IT',
    },
    {
        label: 'kannada',
        value: 'kn',
    },
    {
        label: '한국어',
        value: 'ko-KR',
    },
    {
        label: 'Lao (Laos)',
        value: 'lo-LA',
    },
    {
        label: 'Malay',
        value: 'ms-MY',
    },
    {
        label: 'malayalam',
        value: 'ml',
    },
    {
        label: 'manipuri',
        value: 'mni',
    },
    {
        label: 'marathi',
        value: 'mr',
    },
    {
        label: 'NepaliNepal',
        value: 'ne-NP',
    },
    {
        label: 'oriya',
        value: 'or',
    },
    {
        label: 'panjabi',
        value: 'pa',
    },
    {
        label: 'Polish',
        value: 'pl-PL',
    },
    {
        label: 'portuguese (Brazil)',
        value: 'pt-BR',
    },
    {
        label: 'romanian',
        value: 'ro',
    },
    {
        label: 'Serbian',
        value: 'sr',
    },
    {
        label: 'Sinhala',
        value: 'si-LK',
    },
    {
        label: 'slovak',
        value: 'sk-SK',
    },
    {
        label: 'swedish',
        value: 'sv-SE',
    },
    {
        label: 'Filipino',
        value: 'tl-PH',
    },
    {
        label: 'tamil',
        value: 'ta',
    },
    {
        label: 'telugu',
        value: 'te',
    },
    {
        label: 'Thai',
        value: 'th-TH',
    },
    {
        label: 'Turkish',
        value: 'tr-TR',
    },
    {
        label: 'Ukrainian',
        value: 'uk-UA',
    },
    {
        label: 'urduIndia',
        value: 'ur-IN',
    },
    {
        label: 'Urdu',
        value: 'ur-PK',
    },
    {
        label: 'Vietnamese',
        value: 'vi-VN',
    },
];
exports.langList = _langList.map(function (_a) {
    var label = _a.label, value = _a.value;
    return ({
        label: label,
        value: value,
        alias: value.substring(0, 1).toUpperCase() + value.substring(1, 2),
    });
});
exports.defaultLangList = exports.langList.slice(0, 7);
