"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VERSION = exports.PANDA_EXUI = exports.PANDA_INSTANCES = exports.URL = exports.BROADCAST_CHANNEL_TOPIC = exports.PANDA_EVENT = exports.PANDA_DEBUG_SEARCH_KEY = exports.DEFAULT_LANG = exports.DEFAULT_SEARCH_KEY = exports.DEFAULT_COOKIE_KEY = exports.DEFAULT_LOCAL_KEY = void 0;
exports.DEFAULT_LOCAL_KEY = 'PANDA_LANG';
exports.DEFAULT_COOKIE_KEY = 'x-hng';
exports.DEFAULT_SEARCH_KEY = 'lang';
exports.DEFAULT_LANG = 'zh-CN';
exports.PANDA_DEBUG_SEARCH_KEY = 'panda_debug';
exports.PANDA_EVENT = {
    /** 语种发生变化 */
    CHANGE_LANG: 'CHANGE_LANG',
    /** cdn实例初始化完毕 */
    I18N_INIT: 'I18N_INIT',
};
exports.BROADCAST_CHANNEL_TOPIC = 'Panda_Broadcast_Channel';
exports.URL = {
    'pack-daily': 'https://assets-daily.cainiao-inc.com',
    'pack-prod': 'https://cn.alicdn.com',
};
exports.PANDA_INSTANCES = '__PANDA_INSTANCES';
exports.PANDA_EXUI = '_PANDA_EXUI';
exports.VERSION = '0.2.43';
__exportStar(require("./lang"), exports);
